<template>
  <b-container class="mt-5">
    <b-row>
      <b-col sm="12">
        <b-card header-tag="header" footer-tag="footer">
          <b-row>
            <b-col sm="7">
              <h4 id="traffic" class="card-title mb-0">Ajuste del Software</h4>
              <div class="small text-muted">Administre las características visuales del software</div>
            </b-col>
          </b-row>
        </b-card>

        <div>
          <b-card no-body>
            <b-tabs pills card>
              <b-tab title="Título" active>
                <b-card-text>
                  <b-row>
                    <b-col>
                      <b-form-group label="Proyecto" class="settings-proyecto-label-custom" description="Ingrese el nombre de la empresa que va a utilzar el software">
                        <b-form-input v-model="form.proyectName" type="text" required placeholder="Nombre de Proyecto"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
              <b-tab title="Imágenes">
                <b-card-text>
                  <b-row>
                    <b-col>
                      <b-form-group label="Logo" class="settings-imagen-label-custom" description="Medidas en relación a 150x50">                       
                        <Imagen :images="form.logo" @load-image="form.logo = $event" typeImage="Logo"/>                                                                    
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group label="Favicon" class="settings-imagen-label-custom" description="Medidas en relación a 50x50">
                        <Imagen :images="form.favicon" @load-image="form.favicon = $event" typeImage="Favicon"/>
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group label="Imagen Login" class="settings-imagen-label-custom" description="Medidas en relación a 1152x754">
                        <Imagen :images="form.imageLogin" @load-image="form.imageLogin = $event" typeImage="ImageLogin"/>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
              <b-tab title="Colores">
                <b-card-text>
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-form-group label="Color Principal">
                            <b-form-input v-model="form.colorPrimary" type="color"></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group label="Color Menu Sidebar">
                            <b-form-input v-model="form.colorTitleMenu" type="color"></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group label="Color Hover Sidebar">
                            <b-form-input v-model="form.colorSelectMenu" type="color"></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>

        <b-card header-tag="header" footer-tag="footer">
          <b-row>
            <b-col>
              <b-button variant="dark" class="pull-right" @click="guardar()">Guardar</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import serviceAPI from './services'
  import Imagen from '@/components/inc/image/image' 

  export default {
    components: {
      Imagen,
    },
    data () {
      return {
        form: {
          logo: [],
          favicon: [],
          imageLogin: [],
          proyectName: '',
          colorPrimary: '',
          colorTitleMenu: '',
          colorSelectMenu: '',
        }
      }
    },
    mounted() {
      this.cargar()
    },
    methods: {
      cargar () {
        var result = serviceAPI.obtener()

        result.then((response) => {
          var data = response.data

          if(data.settings[0].value) {
            this.form.logo = [{ path: data.settings[0].value }]
          }

          if(data.settings[1].value) {
            this.form.favicon = [{ path: data.settings[1].value }]
          }

          if(data.settings[2].value) {
            this.form.imageLogin = [{ path: data.settings[2].value }]
          }

          this.form.proyectName = data.settings[3].value
          this.form.colorPrimary = data.settings[4].value
          this.form.colorTitleMenu = data.settings[5].value
          this.form.colorSelectMenu = data.settings[6].value
        })
      },
      guardar () {
        let loader = this.$loading.show()

        var logo = new FormData();
        this.form.logo.forEach((value, key) => {
          logo.append(key, value.path);
        })

        var favicon = new FormData();
        this.form.favicon.forEach((value, key) => {
          favicon.append(key, value.path);
        })

        var imageLogin = new FormData();
        this.form.imageLogin.forEach((value, key) => {
          imageLogin.append(key, value.path);
        })

        var result = serviceAPI.guardar(JSON.stringify(this.form), logo, favicon, imageLogin)

        result.then((response) => {
          var data = response.data
          if (data.status) {
            Session.setSessionSettings(data)
            this.$awn.success("Configuración Guardada")
            this.$router.push({ name: 'Init' })
          } else {
            this.$awn.info("Faltan cargar algunos campos")
          }

          loader.hide()
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
          loader.hide()
        });
      },
    },
  }
</script>
<style>
  .settings-proyecto-label-custom legend {
    text-align: left !important;
  }
  .settings-imagen-label-custom legend {
    text-align: left !important;
  }
  legend {
    font-weight: 600;
  }
</style>
