import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtener() {
    var url = ConfigAPI.baseURL + "settings";

    return instance.get(url);
  },
  guardar(data, image1, image2, image3) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();

    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="logo" && key!="favicon" && key!="imageLogin") {
        formData.append(key, data[key]); 
      }

      if(key=="logo") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('logo_' + i, value);
          i++
        })
        formData.append('logo', i);
      }

      if(key=="favicon") {
        var i = 0
        image2.forEach((value, key) => {
          formData.append('favicon_' + i, value);
          i++
        })
        formData.append('favicon', i);
      }

      if(key=="imageLogin") {
        var i = 0
        image3.forEach((value, key) => {
          formData.append('imageLogin_' + i, value);
          i++
        })
        formData.append('imageLogin', i);
      }
    }

    var params = formData

    var url = ConfigAPI.baseURL + "settings";

    return instance.post(url,params,{headers});
  },
}

export default servicesAPI;
